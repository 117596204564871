<template>
  <div class="sub-mip-main col-lg-8 col-md-12 col-sm-12 col-xs-12">
    <div class="mip-content" v-for="item of allData" :key="item.id">
      <h2>
        <router-link
          :to="`details/${item.id}`"
          target="_blank"
          class="mip-cont-title"
          >{{ item.title }}</router-link
        >
      </h2> 
      <i class="mip-time">{{ $moment(item.time).format('YYYY-MM-DD') }}</i>
      <!-- <router-link
          :to="`details/${item.id}`"
          target="_blank"
          class="mip-cont-text"
          v-html="item.content.substring(0,70) + '.....'"
          ></router-link
        > -->
    </div>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="dataCount"
        :small="isMobile"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import DataList from "@/data/index";
export default {
  data() {
    return {
      isMobile: false,
      allData: [],
      pageIndex: 0,
      pageSize: 10,
      pageTotal: 0,
      dataCount: 0,
    };
  },
  created() {
    if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad)/i)) {
      this.isMobile = true;
    }
    this.requestList();
    this.dataCount = DataList.length;
  },
  methods: {
    requestList() {
      this.pageIndex++;
      const star = (this.pageIndex - 1) * 10;
      const end = this.pageIndex * 10;
      this.allData = DataList.slice(star, end);
      // console.log(this.allData);
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-mip-main {
  //   flex: 1;
  position: relative;
  min-height: 500px;
  //   margin-right: 15px;
  padding: 0 20px;
  background-color: #fff;
  .mip-content {
    width: 100%;
    /* height: 135px; */
    padding: 20px 0 10px;
    border-bottom: 1px solid #ebebeb;
    position: relative;
    overflow: hidden;

    .mip-cont-text,
    .mip-cont-title,
    .mip-time {
      display: block;
    }

    .mip-cont-title {
      line-height: 32px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
    }

    .mip-cont-title:hover {
      color: #E6A23C;
    }

    .mip-time {
      height: 28px;
      line-height: 28px;
      color: #999;
      font-size: 100%;
      font-style: normal;
      font-size: 12px;
    }

    .mip-cont-text {
      font-size: 14px;
    //   height: 44px;
    //   line-height: 22px;
    //   overflow: hidden;
    }
  }

  .pagination {
    margin: 0;
    padding: 25px 0 25px 0;
    text-align: center;
    display: block;
  }
}
</style>